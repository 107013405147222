export var config = {
  menuUrl: '',
  purMenuUrl: '',
};

var setConfig = (newConfig) => {
  config = {
    ...config,
    ...newConfig,
  };
};

fetch('//' + window.location.host + '/config.json')
  .then(response => response.json())
  .catch(err => err)
  .then(res => {
    if (res) {
      setConfig({ ...res });
    }
  });


export default config;