import {
  CONTACTS_DATA_RECEIVED,
  CONTACTS_DATA_FETCH,
  CONTACTS_DATA_ERROR,
} from '../actions/types';

const defaultState={
  requestDate: null,
  ready: false,
  loading: false,
  error: null,

  // Minimal data required to render contacts view in sane state.
  ContactsView: [],
};

function contactsReducer(state = defaultState, action) {
  switch (action.type) {
    case CONTACTS_DATA_FETCH:
      return {
        ...state,
        loading: action.status,
        error: null,
      };

    case CONTACTS_DATA_RECEIVED:
      return {
        ...state,
        ready: true,
        loading: false,
        error: null,
        ContactsView: action.data,
      };

    case CONTACTS_DATA_ERROR:
      return {
        ...state,
        requestDate: null,
        ready: false,
        loading: false,
        error: action.err,
      };
    
    default:
      break;
  }

  return state;
}

export default contactsReducer;
