import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import Loadable from 'react-loadable';
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import './index.css';
import './shame.css';
import './typefaces/continental-stag-sans-a/index.css';
import * as version from './version';
import { store } from './store';
import Loading from './components/Loading';
import theme from './theme';
import './config.js';

console.info(`App build version: ${version.build}-1`); // eslint-disable-line no-console

function main() {
  const loader = async () => {
    return import(/* webpackChunkName: "app" */ './App');
  };

  // Async loading support.
  const LoadableApp = Loadable({
    loader,
    loading: Loading,
    timeout: 20000,
    delay: 300,
  });

  ReactDOM.render(
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Router>
            <LoadableApp />
          </Router>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>,
    document.getElementById('root')
  );
}

main();
