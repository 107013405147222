import {
  EVENTS_DATA_RECEIVED,
  EVENTS_DATA_FETCH,
  EVENTS_DATA_ERROR,
  EVENTS_SEEN_STATE,
  AUTH_LOGOUT,
} from '../actions/types';

const defaultState={
  requestDate: null,
  ready: false,
  loading: false,
  error: null,

  // Minimal data required to render trainings view in sane state.
  EventsView: [],
  timestamp: null,
};

function getNewArray(state, data, attend) {
  var localArray = state.EventsView;
  for(let i = 0; i < localArray.length; i++) {
    if(localArray[i].ID === data.ID) {
      localArray[i].seen = true;
      localArray[i].attend = attend;
      return localArray;
    }
  }
}

function eventsReducer(state = defaultState, action) {
  switch (action.type) {
    case EVENTS_DATA_FETCH:
      return {
        ...state,
        loading: action.status,
        error: null,
        ready: false,
      };

    case EVENTS_DATA_RECEIVED:
      return {
        ...state,
        error: null,
        loading: false,
        ready: true,
        EventsView: action.data.events,
        timestamp: action.data.timestamp,
      };

    case EVENTS_DATA_ERROR:
      return {
        ...state,
        requestDate: null,
        ready: false,
        loading: false,
        error: action.err,
      };

    case EVENTS_SEEN_STATE:
      return {
        ...state,
        ready: true,
        loading: false,
        error: null,
        EventsView: getNewArray(state, action.data, action.attend),
      };

    case AUTH_LOGOUT: return defaultState;

    default:
      break;
  }
  return state;
}

export default eventsReducer;
