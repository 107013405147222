import {
  IMPRESSUM_DATA_RECEIVED,
  IMPRESSUM_DATA_FETCH,
  IMPRESSUM_DATA_ERROR,
} from '../actions/types';

const defaultState={
  requestDate: null,
  ready: false,
  loading: false,
  error: null,

  Impressum: {
    content: null,
  },
};

function impressumReducer(state = defaultState, action) {
  switch (action.type) {
    case IMPRESSUM_DATA_FETCH:
      return {
        ...state,
        loading: action.status,
        error: null,
      };

    case IMPRESSUM_DATA_RECEIVED:
      return {
        ...state,
        ready: true,
        loading: false,
        error: null,
        Impressum: action.data,
      };

    case IMPRESSUM_DATA_ERROR:
      return {
        ...state,
        requestDate: null,
        ready: false,
        loading: false,
        error: action.err,
      };

    default:
      break;
  }

  return state;
}

export default impressumReducer;
