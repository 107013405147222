import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';

function handleRetry(event) {
  event.preventDefault();

  window.location.reload();
}

function Loading(props) {
  if (props.timedOut) {
    return <div
      style={{
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress size={24} color="primary" />
      <div id="loader">Taking a long time... &mdash; <button onClick={ handleRetry }>Retry</button></div>;
    </div>;
  } else if (props.pastDelay) {
    return <div
      style={{
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress size={24} color="primary" />
    </div>;
  } else {
    return null;
  }
}

Loading.propTypes = {
  error: PropTypes.object,
  timedOut: PropTypes.bool,
  pastDelay: PropTypes.bool,
};

export default Loading;
