import {
  SUTO_VALUE_SET,
  SUTO_TABLE_ADD,
  SUTO_TABLE_DELETE,
  SUTO_TABLE_EDIT,
  SUTO_DATA_RECEIVED,
  SUTO_DATA_FETCH,
  SUTO_TOOL_DATA_RECEIVED,
  SUTO_TOOL_DATA_EDIT,
  SUTO_TOOL_DATA_RESET,
  SUTO_SUPPLIER_TOOLS_RECEIVED,
  SUTO_SUPPLIER_TOOLS_RESET,
  SUTO_DATA_ERROR,
  SUTO_TOOL_SELECT,
  AUTH_LOGOUT,
  SUTO_TOOLS_DATA_RECEIVED,
} from '../actions/types';

const defaultState = {
  loading: false,
  error: null,
  editing: -1,
  selected: -1,
  notifyBlocked: "",
  sutoRole: false,
  tools: [],
  count: 0,
  supplierTools: [],
  toolTypes: [],
  changes: {},
  units: {
    size: [],
    weight: [],
  },
  toolData: {
    attachments: [],
    materialData: [],
  },
};

function appendToTable(tool, field, values) {
  const copy = {...tool};
  const attachmentsCopy = [...copy[field]];
  if(typeof value === 'object') {
    attachmentsCopy.push(values);
  } else {
    for(let i = 0; i < values.length; i++) {
      attachmentsCopy.push(values[i]);
    }
  }
  copy[field] = attachmentsCopy;
  return copy;
}

function removeFromTable(tool, field, idx) {
  const copy = {...tool};
  const materialsCopy = [...copy[field]];
  materialsCopy.splice(idx, 1);
  copy[field] = materialsCopy;
  return copy;
}

function editTable(tool, field, idx, value) {
  const copy = {...tool};
  const attachmentsCopy = [...copy[field]];
  attachmentsCopy[idx].description = value;
  copy.attachments = attachmentsCopy;
  return copy;
}

function editTool(tool, field, value) {
  const copy = {...tool};
  copy[field] = value;
  return copy;
}

function setChanges(curChanges, field, value) {
  let copy = {...curChanges};
  copy[field] = value;
  return copy;
}

function sutoReducer(state = defaultState, action) {
  switch (action.type) {
    case SUTO_DATA_FETCH:
      return {
        ...state,
        loading: true,
      };

    case SUTO_DATA_ERROR:
      return {
        ...state,
        error: action.err,
        loading: false,
      };

    case SUTO_TOOL_SELECT:
      return {
        ...state,
        selected: action.toolID,
        notifyBlocked: action.notifyBlocked,
        toolData: action.reset ? {
          attachments: [],
          materialData: [],
        } : state.toolData,
      };

    case SUTO_DATA_RECEIVED:
      return {
        ...state,
        loading: false,
        error: null,
        changes: {},
        sutoRole: action.data.sutoRole,
        units: action.data.units,
        toolTypes: action.data.toolTypes,
      };

    case SUTO_TOOLS_DATA_RECEIVED:
      return {
        ...state,
        changes: {},
        ...action.data,
      };

    case SUTO_TOOL_DATA_RECEIVED:
      return {
        ...state,
        toolData: action.data,
        notifyBlocked: action.data.notifyBlocked || "",
        loading: false,
        changes: {},
        editing: -1,
        supplierTools: [],
        error: null,
      };

    case SUTO_SUPPLIER_TOOLS_RECEIVED:
      return {
        ...state,
        loading: false,
        error: null,
        changes: {},
        editing: -1,
        supplierTools: action.data.tools,
        selected: -1,
        toolData: {
          attachments: [],
          materialData: [],
        },
      };

    case SUTO_SUPPLIER_TOOLS_RESET:
      return {
        ...state,
        loading: false,
        error: null,
        changes: {},
        editing: -1,
        supplierTools: [],
        selected: -1,
      };

    case SUTO_TOOL_DATA_RESET:
      return {
        ...state,
        toolData: {
          attachments: [],
          materialData: [],
        },
        loading: false,
        changes: {},
        editing: -1,
      };

    case SUTO_TOOL_DATA_EDIT:
      return {
        ...state,
        toolData: action.data,
        editing: -1,
      };

    case SUTO_VALUE_SET:
      return {
        ...state,
        changes: setChanges(state.changes, action.field, action.value),
        toolData: editTool(state.toolData, action.field, action.value),
        editing: state.toolData.toolID,
      };

    case SUTO_TABLE_ADD:
      return {
        ...state,
        toolData: appendToTable(state.toolData, action.field, action.values),
      };

    case SUTO_TABLE_DELETE:
      return {
        ...state,
        toolData: removeFromTable(state.toolData, action.field, action.idx),
      };

    case SUTO_TABLE_EDIT:
      return {
        ...state,
        toolData: editTable(state.toolData, action.field, action.idx, action.value),
      };

    case AUTH_LOGOUT: return defaultState;

    default:
      break;
  }
  return state;
}

export default sutoReducer;
