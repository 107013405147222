import {
  SERVICE_WORKER_NEW_CONTENT,
} from '../actions/types';

const defaultState={
  updateAvailable: false,
};

function commonReducer(state = defaultState, action) {
  switch (action.type) {
    case SERVICE_WORKER_NEW_CONTENT:
      return { ...state, updateAvailable: true };

    default:
      return state;
  }
}

export default commonReducer;
