// Redux action types. Please keep alphabetically sorted and grouped.

export const SERVICE_WORKER_NEW_CONTENT = 'SERVICE_WORKER_NEW_CONTENT';

export const AUTH_AUTHENTICATING = 'AUTH_AUTHENTICATING';
export const AUTH_AUTHENTICATED = 'AUTH_AUTHENTICATED';
export const AUTH_LOGON = 'AUTH_LOGON';
export const AUTH_LOGON_ERROR = 'AUTH_LOGON_ERROR';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_URL = 'AUTH_URL';

export const PERFORMANCE_DATA_FETCH = 'PERFORMANCE_DATA_FETCH';
export const PERFORMANCE_DATA_RECEIVED = 'PERFORMANCE_DATA_RECEIVED';
export const PERFORMANCE_DATA_ERROR = 'PERFORMANCE_DATA_ERROR';
export const PERFORMANCE_DATA_RESET = 'PERFORMANCE_DATA_RESET';
export const PERFORMANCE_SEEN_STATE = 'PERFORMANCE_SEEN_STATE';

export const SCU_DATA_FETCH = 'SCU_DATA_FETCH';
export const SCU_DATA_RECEIVED = 'SCU_DATA_RECEIVED';
export const SCU_DATA_ERROR = 'SCU_DATA_ERROR';
export const SCU_FILTERS_FETCH = 'SCU_FILTERS_FETCH';
export const SCU_FILTERS_RECEIVED = 'SCU_FILTERS_RECEIVED';
export const SCU_FILTERS_ERROR = 'SCU_FILTERS_ERROR';
export const SCU_DATA_RESET = 'SCU_DATA_RESET';
export const SCU_DATA_CALC_TIMESCALES = 'SCU_DATA_CALC_TIMESCALES';
export const SCU_TABLE_FETCH = 'SCU_TABLE_FETCH';
export const SCU_TABLE_RECEIVED = 'SCU_TABLE_RECEIVED';
export const SCU_TABLE_ERROR = 'SCU_TABLE_ERROR';

export const TRAININGS_DATA_FETCH = 'TRAININGS_DATA_FETCH';
export const TRAININGS_DATA_RECEIVED = 'TRAININGS_DATA_RECEIVED';
export const TRAININGS_DATA_ERROR = 'TRAININGS_DATA_ERROR';
export const TRAININGS_SEEN_STATE = 'TRAININGS_SEEN_STATE';

export const CONTACTS_DATA_FETCH = 'CONTACTS_DATA_FETCH';
export const CONTACTS_DATA_RECEIVED = 'CONTACTS_DATA_RECEIVED';
export const CONTACTS_DATA_ERROR = 'CONTACTS_DATA_ERROR';

export const INFOS_DATA_FETCH = 'INFOS_DATA_FETCH';
export const INFOS_DATA_RECEIVED = 'INFOS_DATA_RECEIVED';
export const INFOS_DATA_ERROR = 'INFOS_DATA_ERROR';
export const INFOS_SEEN_STATE = 'INFOS_SEEN_STATE';

export const EVENTS_DATA_FETCH = 'EVENTS_DATA_FETCH';
export const EVENTS_DATA_RECEIVED = 'EVENTS_DATA_RECEIVED';
export const EVENTS_DATA_ERROR = 'EVENTS_DATA_ERROR';
export const EVENTS_SEEN_STATE = 'EVENTS_SEEN_STATE';

export const NOTIFICATIONS_DATA_FETCH = 'NOTIFICATIONS_DATA_FETCH';
export const NOTIFICATIONS_DATA_RECEIVED = 'NOTIFICATIONS_DATA_RECEIVED';
export const NOTIFICATIONS_DATA_ERROR = 'NOTIFICATIONS_DATA_ERROR';
export const NOTIFICATIONS_DECREASE = 'NOTIFICATIONS_RECREASE';

export const ABOUT_DATA_FETCH = 'ABOUT_DATA_FETCH';
export const ABOUT_DATA_RECEIVED = 'ABOUT_DATA_RECEIVED';
export const ABOUT_DATA_ERROR = 'ABOUT_DATA_ERROR';

export const DPN_DATA_RECEIVED = 'DPN_DATA_RECEIVED';
export const DPN_DATA_FETCH = 'DPN_DATA_FETCH';
export const DPN_DATA_ERROR = 'DPN_DATA_ERROR';
export const DPN_ACCEPTED = 'DPN_ACCEPTED';

export const IMPRESSUM_DATA_FETCH = 'IMPRESSUM_DATA_FETCH';
export const IMPRESSUM_DATA_RECEIVED = 'IMPRESSUM_DATA_RECEIVED';
export const IMPRESSUM_DATA_ERROR = 'IMPRESSUM_DATA_ERROR';

export const TOU_DATA_FETCH = 'TOU_DATA_FETCH';
export const TOU_DATA_RECEIVED = 'TOU_DATA_RECEIVED';
export const TOU_DATA_ERROR = 'TOU_DATA_ERROR';
export const TOU_ACCEPTED = 'TOU_ACCEPTED';

export const SUTO_VALUE_SET = 'SUTO_VALUE_SET';
export const SUTO_TABLE_ADD = 'SUTO_TABLE_ADD';
export const SUTO_TOOL_DATA_EDIT = 'SUTO_TOOL_DATA_EDIT';
export const SUTO_TOOL_SELECT = 'SUTO_TOOL_SELECT';
export const SUTO_TABLE_DELETE = 'SUTO_TABLE_DELETE';
export const SUTO_TABLE_EDIT = 'SUTO_TABLE_EDIT';
export const SUTO_DATA_FETCH = 'SUTO_DATA_FETCH';
export const SUTO_DATA_RECEIVED = 'SUTO_DATA_RECEIVED';
export const SUTO_DATA_ERROR = 'SUTO_DATA_ERROR';
export const SUTO_TOOL_DATA_RECEIVED = 'SUTO_TOOL_DATA_RECEIVED';
export const SUTO_SUPPLIER_TOOLS_RESET = 'SUTO_SUPPLIER_TOOLS_RESET';
export const SUTO_TOOL_DATA_RESET = 'SUTO_TOOL_DATA_RESET';
export const SUTO_SUPPLIER_TOOLS_RECEIVED = 'SUTO_SUPPLIER_TOOLS_RECEIVED';
export const SUTO_TOOLS_DATA_RECEIVED = "SUTO_TOOLS_DATA_RECEIVED";

export const SUPPLIER_DATA_FETCH = 'SUPPLIER_DATA_FETCH';
export const SUPPLIER_DATA_RECEIVED = 'SUPPLIER_DATA_RECEIVED';
export const SUPPLIER_DATA_ERROR = 'SUPPLIER_DATA_ERROR';
export const SUPPLIER_DATA_RESET = 'SUPPLIER_DATA_RESET';
