import {
  TOU_DATA_RECEIVED,
  TOU_DATA_FETCH,
  TOU_DATA_ERROR,
  TOU_ACCEPTED,
} from '../actions/types';

const defaultState={
  requestDate: null,
  ready: false,
  loading: false,
  error: null,

  Tou: {
    accepted: false,
    content: null,
    countries: [],
  },
};

function getAcceptedState(tou) {
  return {
    ...tou,
    accepted: true,
  };
}

function touReducer(state = defaultState, action) {
  switch (action.type) {
    case TOU_DATA_FETCH:
      return {
        ...state,
        loading: action.status,
        error: null,
      };

    case TOU_DATA_RECEIVED:
      return {
        ...state,
        ready: true,
        loading: false,
        error: null,
        Tou: action.data,
      };

    case TOU_DATA_ERROR:
      return {
        ...state,
        requestDate: null,
        ready: false,
        loading: false,
        error: action.err,
      };
    case TOU_ACCEPTED:
      return {
        ...state,
        ready: true,
        loading: false,
        error: null,
        Tou: getAcceptedState(state.Tou),
      };

    default:
      break;
  }

  return state;
}

export default touReducer;
