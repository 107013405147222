/* eslint-disable camelcase */
import {
  AUTH_AUTHENTICATING,
  AUTH_AUTHENTICATED,
  AUTH_LOGON,
  AUTH_LOGON_ERROR,
  AUTH_LOGOUT,
  AUTH_URL,
} from '../actions/types';

const defaultState = {
  error: '',
  authenticated: false,
  authenticating: true,
  impersonation: false,
  subscription: null,
  roles: [],
  loginUrl: '',
  logoutUrl: '',
  client_id: '',
  tokenUrl: '',
  csrf: '',
  supplier: null,
};

function authReducer(state = defaultState, action) {
  switch (action.type) {
    case AUTH_AUTHENTICATING:
      return {
        ...state,
        authenticating: action.authenticating,
        error: '',
      };

    case AUTH_AUTHENTICATED:
      return {
        ...state,
        authenticated: action.authenticated,
        error: '',
      };

    case AUTH_LOGON:
      return {
        ...state,
        impersonation: action.logon.impersonation,
        subscription: action.logon.pushSubscription,
        roles: action.logon.roles || [],
        csrf: action.logon.csrf,
        error: '',
        supplier: action.supplier || null,
      };
    
    case AUTH_LOGON_ERROR:
      return {
        ...state,
        error: action.error,
        authenticated: false,
        subscription: null,
      };

    case AUTH_LOGOUT: {
      return {
        ...state,
        authenticated: false,
        impersonation: false,
        subscription: null,
        roles: [],
      };
    }

    case AUTH_URL:
      return {
        ...state,
        loginUrl: action.data.auth,
        client_id: action.data.client_id,
        logoutUrl: action.data.logout,
        tokenUrl: action.data.token,
      };

    default:
      return state;
  }
}

export default authReducer;
