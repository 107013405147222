import {
  ABOUT_DATA_FETCH,
  ABOUT_DATA_RECEIVED,
  ABOUT_DATA_ERROR,
} from '../actions/types';

const defaultState={
  requestDate: null,
  ready: false,
  loading: false,
  error: null,

  // Minimal data required to render about data
  About: {
    API: "",
    backend: "",
  },
};

function aboutReducer(state = defaultState, action) {
  switch (action.type) {
    case ABOUT_DATA_FETCH:
      return {
        ...state,
        loading: action.status,
        error: null,
      };

    case ABOUT_DATA_RECEIVED:
      return {
        ...state,
        ready: true,
        loading: false,
        error: null,
        About: action.data,
      };

    case ABOUT_DATA_ERROR:
      return {
        ...state,
        requestDate: null,
        ready: false,
        loading: false,
        error: action.err,
      };

    default:
      break;
  }

  return state;
}

export default aboutReducer;
