import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';

import thunkMiddleware from 'redux-thunk';

import commonReducer from './reducers/common';
import authReducer from './reducers/auth';
import performanceReducer from './reducers/performance';
import trainingsReducer from './reducers/trainings';
import contactsReducer from './reducers/contacts';
import infosReducer from './reducers/infos';
import eventsReducer from './reducers/events';
import notificationsReducer from './reducers/notifications';
import aboutReducer from './reducers/about';
import dpnReducer from './reducers/dpn';
import impressumReducer from './reducers/impressum';
import sutoReducer from './reducers/suto';
import touReducer from './reducers/tou';
import supplierReducer from './reducers/supplier';
import scuReducer from './reducers/scu';

const loggerMiddleware = createLogger();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  combineReducers({
    common: commonReducer,
    auth: authReducer,
    performance: performanceReducer,
    scu: scuReducer,
    trainings: trainingsReducer,
    contacts: contactsReducer,
    infos: infosReducer,
    events: eventsReducer,
    notifications: notificationsReducer,
    about: aboutReducer,
    dpn: dpnReducer,
    impressum: impressumReducer,
    tou: touReducer,
    suto: sutoReducer,
    supplier: supplierReducer,
  }),
  composeEnhancers(applyMiddleware(
    thunkMiddleware,

    loggerMiddleware // Must be last.
  ))
);

export default store;
