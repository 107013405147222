import {
  DPN_DATA_RECEIVED,
  DPN_DATA_FETCH,
  DPN_DATA_ERROR,
  DPN_ACCEPTED,
} from '../actions/types';

const defaultState={
  requestDate: null,
  ready: false,
  loading: false,
  error: null,

  // Minimal data required to render the DPN
  DPN: {
    accepted: false,
    content: null,
    countries: [],
  },
};

function getAcceptedState(dpn) {
  return {
    ...dpn,
    accepted: true,
  };
}

function dpnReducer(state = defaultState, action) {
  switch (action.type) {
    case DPN_DATA_FETCH:
      return {
        ...state,
        loading: action.status,
        error: null,
      };

    case DPN_DATA_RECEIVED:
      return {
        ...state,
        ready: true,
        loading: false,
        error: null,
        DPN: action.data,
      };

    case DPN_DATA_ERROR:
      return {
        ...state,
        requestDate: null,
        ready: false,
        loading: false,
        error: action.err,
      };

    case DPN_ACCEPTED:
      return {
        ...state,
        ready: true,
        loading: false,
        error: null,
        DPN: getAcceptedState(state.DPN),
      };

    default:
      break;
  }

  return state;
}

export default dpnReducer;
