import {
  PERFORMANCE_DATA_FETCH,
  PERFORMANCE_DATA_RECEIVED,
  PERFORMANCE_DATA_ERROR,
  PERFORMANCE_DATA_RESET,
  PERFORMANCE_SEEN_STATE,
  AUTH_LOGOUT,
} from '../actions/types';

const defaultState = {
  requestDate: null,
  ready: false,
  loading: false,
  error: null,

  // Minimal data required to render performance view in sane state.
  PerformanceView: {
    kpis: [],
  },
};

function updateArray(array, data) {
  return array.map(training => {
    if (training.id !== data.id) {
      return training;
    }

    return {
      ...training,
      badge: null,
    };
  });
}

function performanceReducer(state = defaultState, action) {
  switch (action.type) {
    case PERFORMANCE_DATA_FETCH:
      return {
        ...state,
        loading: action.status,
        error: null,
      };

    case PERFORMANCE_DATA_RECEIVED:
      return {
        ...state,
        ready: true,
        loading: false,
        error: null,
        PerformanceView: action.data,
      };

    case PERFORMANCE_DATA_ERROR:
      return {
        ...state,
        requestDate: null,
        ready: false,
        loading: false,
        error: action.err,
      };

    case PERFORMANCE_DATA_RESET:
      return {
        ...state,
        requestDate: null,
        ready: false,
        loading: false,
        error: false,
        PerformanceView: {
          kpis: [],
        },
      };

    case PERFORMANCE_SEEN_STATE:
      return {
        ...state,
        ready: true,
        loading: false,
        error: null,
        PerformanceView: {
          kpis: updateArray(state.PerformanceView.kpis, action.data),
        },
      };

    case AUTH_LOGOUT: return defaultState;

    default:
      break;
  }

  return state;
}

export default performanceReducer;
