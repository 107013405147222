const continentalGelb = {
  //300:
  500: '#ffa500',
  //700:
  //A200:
  //A400:
  //A700:
};

export default continentalGelb;
