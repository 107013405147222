import {
  SCU_DATA_FETCH,
  SCU_DATA_RECEIVED,
  SCU_DATA_ERROR,
  SCU_FILTERS_FETCH,
  SCU_FILTERS_RECEIVED,
  SCU_FILTERS_ERROR,
  SCU_DATA_RESET,
  SCU_DATA_CALC_TIMESCALES,
  SCU_TABLE_FETCH,
  SCU_TABLE_RECEIVED,
  SCU_TABLE_ERROR,
  AUTH_LOGOUT,
} from '../actions/types';

const defaultState = {
  requestDate: null,
  ready: false,
  loading: false,
  error: null,

  // Minimal data required to render SCU view in sane state.
  Data: {
    data: [],
    filters: {
      years: [],
    },
  },
  Table: {
    labels: [],
    years: [],
  },
};

function calculateTimeScales(arr) {
  let newArr = [];
  for(let i = 0; i < arr.length; i++) {
    newArr.push({
      year: arr[i].year,
      monthly: arr[i].monthly,
      quarterly: calcQuarters(arr[i].monthly),
      yearly: calcYear(arr[i].year, arr[i].monthly),
    });
  }
  return newArr;
}

function calcQuarters(data) {
  let quarters = [];
  let qCount = Math.floor(data.length / 3);
  if(qCount === 0) quarters.push({date: '', con: 0, GR: 0, VO: 0});
  for(let i = 0; i < qCount; i++) {
    let con = 0, GR = 0, VO = 0;
    for(let j = 0; j < 3; j++) {
      con += data[j + i * 3].con;
      GR += data[j + i * 3].GR;
      VO += data[j + i * 3].VO;
    }
    quarters.push({date: 'Quarter ' + (i + 1), con, GR, VO});
  }
  return quarters;
}

function calcYear(year, data) {
  let con = 0, GR = 0, VO = 0;
  for(var i = 0; i < data.length; i++) {
    con += data[i].con;
    GR += data[i].GR;
    VO += data[i].VO;
  }
  return [{ date: year, con, GR, VO }];
}

function scuReducer(state = defaultState, action) {
  switch (action.type) {
    case SCU_DATA_FETCH:
      return {
        ...state,
        loading: action.status,
        error: null,
      };

    case SCU_FILTERS_FETCH:
      return {
        ...state,
        loading: action.status,
        error: null,
      };

    case SCU_DATA_RECEIVED:
      return !action.data.filters ? {
        ...state,
        ready: true,
        loading: false,
        error: null,
        Data: {
          ...state.Data,
          data: action.data.data,
        },
      } : {
        ...state,
        ready: true,
        loading: false,
        error: null,
        Data: action.data,
      };

    case SCU_DATA_CALC_TIMESCALES:
      return {
        ...state,
        Data: {
          ...state.Data,
          data: calculateTimeScales(state.Data.data),
        },
      };

    case SCU_FILTERS_RECEIVED:
      return {
        ...state,
        ready: true,
        loading: false,
        error: null,
        Data: {
          ...state.Data,
          filters: action.data,
        },
      };

    case SCU_DATA_ERROR:
      return {
        ...state,
        requestDate: null,
        ready: false,
        loading: false,
        error: action.err,
      };

    case SCU_FILTERS_ERROR:
      return {
        ...state,
        requestDate: null,
        ready: false,
        loading: false,
        error: action.err,
      };

    case SCU_DATA_RESET:
      return {
        ...state,
        requestDate: null,
        ready: false,
        loading: false,
        error: false,
        Data: {
          data: [],
          filters: {
            years: [],
          },
        },
        Table: {
          years: [],
          lables: [],
        },
      };

    case SCU_TABLE_FETCH: {
      return {
        ...state,
        loading: action.status,
        error: null,
      };
    }
  
    case SCU_TABLE_RECEIVED: {
      return {
        ...state,
        ready: true,
        loading: false,
        error: null,
        Table: action.data,
      };
    }

    case SCU_TABLE_ERROR: {
      return {
        ...state,
        requestDate: null,
        ready: false,
        loading: false,
        error: action.err,
      };
    }

    case AUTH_LOGOUT: return defaultState;

    default:
      break;
  }

  return state;
}

export default scuReducer;
