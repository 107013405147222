import {
  TRAININGS_DATA_RECEIVED,
  TRAININGS_DATA_FETCH,
  TRAININGS_DATA_ERROR,
  TRAININGS_SEEN_STATE,
} from '../actions/types';

const defaultState={
  requestDate: null,
  ready: false,
  loading: false,
  error: null,

  // Minimal data required to render trainings view in sane state.
  TrainingsView: [],
};

function updateObject(array, data) {
  return array.map(training => {
    if (training.ID !== data.ID) {
      return training;
    }

    return {
      ...training,
      seen: true,
      updated: false,
    };
  });
}

function trainingsReducer(state = defaultState, action) {
  switch (action.type) {
    case TRAININGS_DATA_FETCH:
      return {
        ...state,
        loading: action.status,
        error: null,
      };

    case TRAININGS_DATA_RECEIVED:
      return {
        ...state,
        ready: true,
        loading: false,
        error: null,
        TrainingsView: action.data,
      };

    case TRAININGS_DATA_ERROR:
      return {
        ...state,
        requestDate: null,
        ready: false,
        loading: false,
        error: action.err,
      };
    case TRAININGS_SEEN_STATE:
      return {
        ...state,
        ready: true,
        loading: false,
        error: null,
        TrainingsView: updateObject(state.TrainingsView, action.data),
      };

    default:
      break;
  }
  return state;
}

export default trainingsReducer;
