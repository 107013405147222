const continentalGrau = {
  A100: '#f0f0f0',
  A200: '#cdcdcd',
  A400: '#969696',
  A700: '#737373',
  A900: '#323232',
  //A200:
  //A400:
  //A700:
};

export default continentalGrau;
