import {
  SUPPLIER_DATA_RECEIVED,
  SUPPLIER_DATA_FETCH,
  SUPPLIER_DATA_ERROR,
  SUPPLIER_DATA_RESET,
} from '../actions/types';

const defaultState={
  requestDate: null,
  ready: false,
  loading: false,
  error: null,

  // Minimal data required to render supplier view in sane state.
  Supplier: {
    result: 0,
    suppliers: [],
  },
};

function supplierReducer(state = defaultState, action) {
  switch (action.type) {
    case SUPPLIER_DATA_FETCH:
      return {
        ...state,
        loading: action.status,
        error: null,
      };

    case SUPPLIER_DATA_RECEIVED:
      return {
        ...state,
        ready: true,
        loading: false,
        error: null,
        Supplier: action.data,
      };

    case SUPPLIER_DATA_ERROR:
      return {
        ...state,
        requestDate: null,
        ready: false,
        loading: false,
        error: action.err,
      };

    case SUPPLIER_DATA_RESET:
      return {
        ...state,
        Supplier: {
          result: 0,
          suppliers: [],
        },
      };
    default:
      break;
  }

  return state;
}

export default supplierReducer;
