const continentalSchwarz= {
  //300:
  500: '#000000',
  //700:
  //A200:
  //A400:
  //A700:
};

export default continentalSchwarz;
