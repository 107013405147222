import {
  NOTIFICATIONS_DATA_RECEIVED,
  NOTIFICATIONS_DATA_FETCH,
  NOTIFICATIONS_DATA_ERROR,
  NOTIFICATIONS_DECREASE,
} from '../actions/types';

const defaultState={
  requestDate: null,
  ready: false,
  loading: false,
  error: null,

  // Minimal data required to render notifications
  Notifications: {
    events: 0,
    trainings: 0,
    infos: 0,
    kpis: 0,
  },
};

function updateObject(nots, view) {
  return nots[view] > 0 ? {
    ...nots,
    [view]: nots[view] - 1,
  } : {...nots};
}

function notificationsReducer(state = defaultState, action) {
  switch (action.type) {
    case NOTIFICATIONS_DATA_FETCH:
      return {
        ...state,
        loading: action.status,
        error: null,
      };

    case NOTIFICATIONS_DATA_RECEIVED:
      return {
        ...state,
        ready: true,
        loading: false,
        error: null,
        Notifications: action.data,
      };

    case NOTIFICATIONS_DATA_ERROR:
      return {
        ...state,
        requestDate: null,
        ready: false,
        loading: false,
        error: action.err,
      };

    case NOTIFICATIONS_DECREASE:
      return {
        ...state,
        Notifications: updateObject(state.Notifications, action.view),
      };

    default:
      break;
  }
  return state;
}

export default notificationsReducer;
